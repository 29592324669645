import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./views/user/login";
import Error from "views/error";
import Unauthorized from "views/unauthorized";
import AppLayout from "layout/AppLayout";
import Blank from "views/app/Blank";
import RegionsList from "views/app/regions/regionsList";
import AddRegion from "views/app/regions/add";
import UsersList from "views/app/users/usersList";
import AddUser from "views/app/users/add";
import ApplicationsList from "views/app/applications/applicationsList";
import { PrivateRoute } from "auth/privateRoute";
import Settings from "views/app/settings/settings";
import DetailsList from "views/app/details/detailsList";
import AddDetail from "views/app/details/add";
import EmailsList from "views/app/emails/emailsList";
import SendEmail from "views/app/emails/sendEmail";
import EmailView from "views/app/emails/emailView";
import InspectList from "views/app/inspect/inspectList";
import EditInspect from "views/app/inspect/edit";
import CandidatesList from "views/app/candidates/candidatesList";
import PartnersList from "views/app/partners/partnersList";
import OrganizationsList from "views/app/partners/organizationsList";
import EditDate from "views/app/speaking/editDate";
import SpeakingDatesList from "views/app/speaking/datesList";
import NewSessions from "views/app/speaking/newSessions";
import EditSlotPage from "views/app/speaking/editSlotPage";
import SpeakingExaminersList from "views/app/speaking/examinersList";
import SpeakingVenuesList from "views/app/speaking/venuesList";
import BatchHeader from "views/app/details/batchHeader";
import TelegramBroadcast from "views/app/broadcast/telegram";
import PartnerDetails from "views/app/partners/partnerDetails";
import SmsBroadcast from "views/app/broadcast/sms";
import CandidatesListCertificate from "views/app/candidates-certificate/candidatesList";
import EditCandidatePage from "views/app/speaking/editCandidatePage";
import InvigilatorsPage from "views/app/speaking/invigilatorsPage";
import RoomsList from "views/app/speaking/roomsList";
import InvigilatorsList from "views/app/invigilators/invigilatorsList";
import EditInvigilator from "views/app/invigilators/editInvigilator";
import SpeakingRegionsList from "views/app/speaking/regionsList";
import CalendarRegionsList from "views/app/calendarRegions/regionsList";
import AddCalendarRegion from "views/app/calendarRegions/add";
import CandidatesListEOR from "views/app/candidates/candidatesListEOR";
import CandidatesListEORSent from "views/app/candidates/candidatesListEORSent";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />

          <Route path="/error" element={<Error />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route
            path="/app"
            element={
              <PrivateRoute>
                <AppLayout />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/regions"
            element={
              <PrivateRoute>
                <RegionsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/regions/add"
            element={
              <PrivateRoute>
                <AddRegion />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/regions/:id"
            element={
              <PrivateRoute>
                <AddRegion />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/ieltspartners"
            element={
              <PrivateRoute>
                <PartnersList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/ieltspartners/tg-enquiries"
            element={
              <PrivateRoute>
                <OrganizationsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/ieltspartners/:id"
            element={
              <PrivateRoute>
                <PartnerDetails />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/emails"
            element={
              <PrivateRoute>
                <EmailsList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/emails/email/:id"
            element={
              <PrivateRoute>
                <EmailView />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/emails/send"
            element={
              <PrivateRoute>
                <SendEmail />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/broadcast"
            element={
              <PrivateRoute>
                <TelegramBroadcast />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/sms"
            element={
              <PrivateRoute>
                <SmsBroadcast />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/details"
            element={
              <PrivateRoute>
                <DetailsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/details/batch-header"
            element={
              <PrivateRoute>
                <BatchHeader />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/details/:id"
            element={
              <PrivateRoute>
                <AddDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/eor"
            element={
              <PrivateRoute>
                <CandidatesListEORSent />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/eor-cand"
            element={
              <PrivateRoute>
                <CandidatesListEOR />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/inspect"
            element={
              <PrivateRoute>
                <InspectList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/inspect/candidates"
            element={
              <PrivateRoute>
                <CandidatesList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/inspect/:id"
            element={
              <PrivateRoute>
                <EditInspect />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/certificate"
            element={
              <PrivateRoute>
                <CandidatesListCertificate />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/users"
            element={
              <PrivateRoute>
                <UsersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/users/add"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/users/:id"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/speaking"
            element={
              <PrivateRoute>
                <SpeakingRegionsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/speaking/date"
            element={
              <PrivateRoute>
                <SpeakingDatesList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/speaking/edit-candidate/:id"
            element={
              <PrivateRoute>
                <EditCandidatePage />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/speaking/date/add"
            element={
              <PrivateRoute>
                <NewSessions />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/speaking/add/:id"
            element={
              <PrivateRoute>
                <NewSessions />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/speaking/examiners"
            element={
              <PrivateRoute>
                <SpeakingExaminersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/speaking/venues"
            element={
              <PrivateRoute>
                <SpeakingVenuesList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/speaking/slot/:id"
            element={
              <PrivateRoute>
                <EditSlotPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/speaking/slot/:id"
            element={
              <PrivateRoute>
                <EditSlotPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/speaking/date/:id"
            element={
              <PrivateRoute>
                <EditDate />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/speaking/invigilators/:id"
            element={
              <PrivateRoute>
                <InvigilatorsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/rooms"
            element={
              <PrivateRoute>
                <RoomsList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/invigilators"
            element={
              <PrivateRoute>
                <InvigilatorsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/invigilators/:id"
            element={
              <PrivateRoute>
                <EditInvigilator />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/calendarregions"
            element={
              <PrivateRoute>
                <CalendarRegionsList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/calendarregions/:id"
            element={
              <PrivateRoute>
                <AddCalendarRegion />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/applications"
            element={
              <PrivateRoute>
                <ApplicationsList />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
