import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const editorConfig = {
  toolbar: [
    ["Undo", "Redo"],
    ["Format", "Font", "FontSize"],
    ["TextColor", "BGColor"],
    ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
    ["Bold", "Italic", "Underline", "Strike", "RemoveFormat", "CopyFormatting"],
    ["Link", "Unlink"],
    [
      "NumberedList",
      "BulletedList",
      "-",
      "Outdent",
      "Indent",
      "-",
      "Blockquote",
    ],
    ["Image", "Table"],
    ["Scayt"],
  ],
  extraPlugins: "justify,format,font,colorbutton",
};

const EORUpdateCandidate = (props) => {
  const { toggle, candidateId } = props;
  const user = useUser();

  const initialValues = {};

  const [values, setValues] = useState({});
  const [valuesToUpdate, setValuesToUpdate] = useState({ eorSent: true });
  const [results, setResults] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [sections, setSections] = useState([]);

  const handleGenerate = async () => {
    let tempValues = { ...valuesToUpdate };
    if (sections.includes("Writing")) {
      tempValues.eorWritingDetails = { requested: true, old: results.WScore };
    }
    if (sections.includes("Speaking")) {
      tempValues.eorSpeakingDetails = { requested: true, old: results.SScore };
    }
    setValuesToUpdate(tempValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(valuesToUpdate);
    const data = { ...valuesToUpdate };
    if (valuesToUpdate.eorReceived) {
      if (!valuesToUpdate.eorReceivedDate) {
        return toast.error("Please select received date");
      }
    }

    if (data.eorWritingDetails.changed) {
      if (!data.eorWritingDetails.new) {
        return toast.error("Please enter new writing score");
      }
      const oldVal = parseFloat(data.eorWritingDetails.old);
      const newVal = parseFloat(data.eorWritingDetails.new);
      data.eorWritingDetails.difference = (newVal - oldVal).toString();
    }
    if (data.eorSpeakingDetails.changed) {
      if (!data.eorSpeakingDetails.new) {
        return toast.error("Please enter new speaking score");
      }
      const oldVal = parseFloat(data.eorSpeakingDetails.old);
      const newVal = parseFloat(data.eorSpeakingDetails.new);
      data.eorSpeakingDetails.difference = (newVal - oldVal)
        .toFixed(1)
        .toString();
    }
    // console.log(data);
    axios
      .post(`/api/eor-candidate/${candidateId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(async (result) => {
        // console.log(result);
        if (result.status === 200) {
          setErrorMessage("");

          setSuccess(true);
          toast.success("Successfully sent");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 400) {
          toast.error(err.response?.data?.error);
        } else {
          setErrorMessage(Object.values(err.response.data.errors)[0]);
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    if (candidateId) {
      axios
        .get(`/api/candidate/${candidateId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then(async (result) => {
          let resultData = await axios.post(
            "/api/get-result",
            {
              id: result.data.CANDIDATE_NUMBER,
              test: result.data.TEST_DATE,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setResults(resultData.data);
          let tempValues = {
            eorWritingDetails: result.data.eorWritingDetails,
            eorSpeakingDetails: result.data.eorSpeakingDetails,
            eorReceivedDate: result.data.eorReceivedDate,
            eorReceived: result.data.eorReceived,
          };
          if (!tempValues.eorWritingDetails.old) {
            tempValues.eorWritingDetails.old = resultData.data.WScore;
          }
          if (!tempValues.eorSpeakingDetails.old) {
            tempValues.eorSpeakingDetails.old = resultData.data.SScore;
          }
          setValuesToUpdate(tempValues);
          setValues(result.data);
          setLoading(false);
        });
    } else {
      setValues(initialValues);
      setLoading(false);
    }
  }, [candidateId]);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Update EOR</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form className="text-left">
                  <p>
                    {values.CANDIDATE_NUMBER} - {values.FAMILY_NAME}{" "}
                    {values.GIVEN_NAME} - {values.TEST_DATE}
                  </p>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Response received?</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          value={
                            valuesToUpdate.eorReceived
                              ? { label: "Yes", value: true }
                              : { label: "No", value: false }
                          }
                          onChange={(e) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              eorReceived: e.value,
                            })
                          }
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      {valuesToUpdate.eorReceived && (
                        <FormGroup>
                          <Label>Received Date</Label>
                          <br />
                          <DatePicker
                            className={"custom-datepicker"}
                            calendarIcon={
                              <i className="simple-icon-calendar" />
                            }
                            clearIcon={<i className="iconsminds-close" />}
                            dayPlaceholder="DD"
                            monthPlaceholder="MM"
                            yearPlaceholder="YYYY"
                            format="dd/MM/y"
                            minDate={
                              new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 100
                                )
                              )
                            }
                            value={
                              valuesToUpdate.eorReceivedDate
                                ? valuesToUpdate.eorReceivedDate
                                : null
                            }
                            showLeadingZeros={true}
                            onChange={(v) => {
                              setValuesToUpdate({
                                ...valuesToUpdate,
                                eorReceivedDate: v,
                              });
                            }}
                          />
                        </FormGroup>
                      )}
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Writing Change Requested?</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          value={
                            valuesToUpdate.eorWritingDetails?.requested
                              ? { label: "Yes", value: true }
                              : { label: "No", value: false }
                          }
                          onChange={(e) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              eorWritingDetails: {
                                ...valuesToUpdate.eorWritingDetails,
                                requested: e.value,
                              },
                            })
                          }
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Speaking Change Requested?</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          value={
                            valuesToUpdate.eorSpeakingDetails?.requested
                              ? { label: "Yes", value: true }
                              : { label: "No", value: false }
                          }
                          onChange={(e) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              eorSpeakingDetails: {
                                ...valuesToUpdate.eorSpeakingDetails,
                                requested: e.value,
                              },
                            })
                          }
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                        />
                      </FormGroup>
                    </Col>

                    {valuesToUpdate.eorWritingDetails?.requested && (
                      <>
                        <Col md="6">
                          <FormGroup>
                            <Label>Old Writing Score</Label>

                            <Input
                              type="text"
                              value={valuesToUpdate.eorWritingDetails.old}
                              defaultValue={results?.WScore}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Writing score changed?</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              value={
                                valuesToUpdate.eorWritingDetails.changed
                                  ? { label: "Yes", value: true }
                                  : { label: "No", value: false }
                              }
                              onChange={(e) =>
                                setValuesToUpdate({
                                  ...valuesToUpdate,
                                  eorWritingDetails: {
                                    ...valuesToUpdate.eorWritingDetails,
                                    changed: e.value,
                                  },
                                })
                              }
                              options={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                              ]}
                            />
                          </FormGroup>
                        </Col>
                        {valuesToUpdate.eorWritingDetails.changed && (
                          <>
                            <Col md="6">
                              <FormGroup>
                                <Label>New Writing Score</Label>

                                <Input
                                  type="text"
                                  value={valuesToUpdate.eorWritingDetails.new}
                                  onChange={(e) =>
                                    setValuesToUpdate({
                                      ...valuesToUpdate,
                                      eorWritingDetails: {
                                        ...valuesToUpdate.eorWritingDetails,
                                        new: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6"></Col>
                          </>
                        )}
                      </>
                    )}

                    {valuesToUpdate.eorSpeakingDetails?.requested && (
                      <>
                        <Col md="6">
                          <FormGroup>
                            <Label>Old Speaking Score</Label>

                            <Input
                              type="text"
                              value={valuesToUpdate.eorSpeakingDetails.old}
                              defaultValue={results?.SScore}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Speaking score changed?</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              value={
                                valuesToUpdate.eorSpeakingDetails.changed
                                  ? { label: "Yes", value: true }
                                  : { label: "No", value: false }
                              }
                              onChange={(e) =>
                                setValuesToUpdate({
                                  ...valuesToUpdate,
                                  eorSpeakingDetails: {
                                    ...valuesToUpdate.eorSpeakingDetails,
                                    changed: e.value,
                                  },
                                })
                              }
                              options={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                              ]}
                            />
                          </FormGroup>
                        </Col>
                        {valuesToUpdate.eorSpeakingDetails.changed && (
                          <>
                            <Col md="6">
                              <FormGroup>
                                <Label>New Speaking Score</Label>

                                <Input
                                  type="text"
                                  value={valuesToUpdate.eorSpeakingDetails.new}
                                  onChange={(e) =>
                                    setValuesToUpdate({
                                      ...valuesToUpdate,
                                      eorSpeakingDetails: {
                                        ...valuesToUpdate.eorSpeakingDetails,
                                        new: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6"></Col>
                          </>
                        )}
                      </>
                    )}
                  </Row>
                </Form>

                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>

                  <Button
                    color={success ? "success" : "primary"}
                    className="top-right-button ml-2"
                    onClick={handleSubmit}
                    disabled={success}
                  >
                    {success ? "Done" : `Update`}
                  </Button>
                </div>
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default EORUpdateCandidate;
